<style scoped lang="less">
  .evaluation-details-draw {
    display: flex;
    flex-direction: column;
  }
  .evaluation-details-content {
    padding: 10px;
    border-top: 1px solid #EEE;
    flex: 1;
    height: 0;
  }
</style>

<template>
  <div class="evaluation-details-draw">
    <fm-title title-text="个人总结填写"></fm-title>
    <DetailsContent @reload="loadData" class="evaluation-details-content" ref="content" v-if="data" :data="data" :type="type"/>
  </div>
</template>

<script>
import { appraisalYearDetailsRequest } from '@/api'
import DetailsContent from '../components/content.vue'

export default {
  components: { DetailsContent },
  props: {
    type: String
  },
  data () {
    return {
      data: null
    }
  },
  methods: {
    async loadData () {
      if (!this.$route.query.id) {
        return this.$router.replace({ name: 'evaluation.index' })
      }
      let param = {
        appraisalYearId: this.$route.query.id
      }

      const res = await appraisalYearDetailsRequest.getMy(param)
      if (res.length) {
        this.data = res[0]
      } else {
        this.$notice.error('数据不存在')
        this.$router.back()
      }
    }
  },
  mounted () {
    if (this.$authFunProxy['evaluation.index-my'] || this.$authFunProxy['evaluation.index-mycheck']) {
      this.loadData() 
    }
  }
}
</script>
